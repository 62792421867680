
























#canthas {
    .username {
        padding: 1px 2px;
        border-style: solid;
        border-width: 1px;
        border-radius: 2px;
    }
}
